.UIdropDown__container{
    position: relative;
    margin: 0 5px;
    z-index: 100;
}

.UIdropDown__icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #706E6B;
}

.header__personIcon:hover {
    cursor: pointer;
}

.UidropDown__content{
    display: flex;
    width: max-content;
    flex-direction: column;
    /* align-items: center; */
    position: absolute;
    background: #ffffff;
    /* border: 2px solid #706E6B; */
    border: 2px solid #e8e8e8;
    color: #112732;
    /* top: 60px;
    right: -20px; */
    /* padding: 10px 0.5rem; */
    border-radius: 5px;

}

.UidropDown__content:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 10px;
    transform: translateX(-50%);
    border: 5px solid;
    /* border-color: transparent transparent #706E6B transparent; */
    border-color: transparent transparent #e8e8e8 transparent;
}

.header__profilItem{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    white-space: nowrap;
}



