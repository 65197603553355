.rw-widget-input {
    color: #495057;
    background-color: #fff;
    box-shadow: none;
    background-clip: padding-box;
    width: inherit;
    font-size: 0.9rem;
}

.rw-widget-picker {
    display: grid;
    overflow: hidden;
    min-height: 38px;
    background-color: #fff;
    border: none; 
    /* border-radius: 4px; */
    /* outline: none; */
    grid-template: 1fr/1fr 1.9em;
    width: 100%;
    font-size: 0.9rem;
}

.rw-state-focus .rw-widget-picker{
    border: none;
    box-shadow: none !important;
    font-size: 0.9rem;
}

.rw-state-focus .rw-widget-picker .rw-widget-input{
    border: none;
    box-shadow: none !important;
    font-size: 0.9rem;
}

.rw-list-option.rw-state-selected, .rw-state-selected.rw-list-option-create {
    background-color: gray;
    border-color: gray;
    color: #ffff;
    font-size: 0.9rem;
}

.selectOptions{
    border: none;
    box-shadow: none !important;
    font-size: 0.9rem;
    cursor: pointer;
}

.option{
    cursor: pointer !important;
}

.rw-list-option:hover, .rw-list-option-create:hover, 
[data-intent=keyboard] .rw-list-option.rw-state-focus:not(.rw-state-selected), 
[data-intent=keyboard] .rw-state-focus.rw-list-option-create:not(.rw-state-selected) {
    background-color: gray;
    border-color: gray;
    color: #fff;
}

.rw-list-empty, .rw-list-optgroup, .rw-list-option, .rw-list-option-create {
    outline: none;
    padding: .25em 1rem;
    text-align: left;
}

