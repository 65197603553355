.autoInputCode{
    width: 38px;
    height: 38px;
    border-radius: 7px;
    border: 2px solid #809FB8;
    text-align: center;
    font-size: 1rem;
    margin: 3px;
}

.autoInputCodeValid{
    width: 38px;
    height: 38px;
    border-radius: 7px;
    border: 2px solid #00B8B8;
    text-align: center;
    font-size: 1rem;
    margin: 3px;
}

.autoInputCode:focus{
    border: 2px solid #809FB8;
}
.autoInputCodeContainer{
    width: 100%;
}

.signin__formMess{
    position: relative;background: #ffffff;
    /* max-width: 700px; */
    /* height: 90%; */
    width: 25%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #797979; */
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
}


.inputMessage{
    width: 31px;
    height: 31px;
    text-align: center;
    border: 1px solid #809FB8;
    border-radius: 5px;
}
.inputMessage:focus{
    border: 1px solid #2174B9;
}
.inputMessage:focus-within{
    border: 1px solid #2174B9;
}

.ownerComment{
    height: 93px;
    max-height: 93px;
    overflow-x: auto;
    color: #505050;
    width: -webkit-fill-available;
    border: 2px solid #D9E1E7;
    border-radius: 6px;
    padding: 3px 7px;
}

.signin__fromContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* margin-top: 1rem; */
}

.signin__FromWrap{
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

/* h4, h6, p {
    color: #B8B8B8;
} */
.colorSpec{
    color: #B8B8B8;
    font-size: 17px;
    color: #2174B9
}
/* h6{
    white-space: nowrap;
    color: #B8B8B8;
} */
.h_Spec{
    white-space: nowrap;   
}

p {
    margin-bottom: unset;
    line-height: 1.5;
}
input:focus-within{
    border: none ;
}

.signin__container{
    /* min-height: 692px; */
    background-image: url("../../assets/images/login_background.jpg");
    background-size: cover;
    height: 100vh;
    font-size: 0.9rem;
}