.accordion-button:not(.collapsed) {
    color: #505050;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.selectionTitleContainer{
    color: #505050;
}

.selectionTitleContainer p{
    cursor: pointer;
    padding-left: 17px;
}

.selectionTitle{
    color: #809FB8;   
}

.activeFile{
    background: #F1F4F9
}