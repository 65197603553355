.entitiesComponent__container{
    /* width: 45%; */
    margin-bottom: 1rem;
    height: 30%;
}

.entitiesComponent__entitiesList_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(221, 219, 218, 1);
}

.entitiesComponent__entitiesList_header label{
    margin: 0 0.25rem 0 0 ;
}

.entitiesList_header_radiosContainer{
    font-size: 0.8rem;
}

.entitiesComponent__entitiesList_addBtn button{
    width: 100%;
}

.entitiesComponent__entitiesList_container{
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.5rem;
    /* min-height: 100px;
    max-height: 167px; */
    height: 100px;
    overflow: auto;
}

.entitiesComponent_pillElement_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
}

.entitiesComponent__pillElement_infos{
    display: flex;
    align-items: center;
    overflow: hidden;
}
.entitiesComponent__pillElement_icon{
    color: blue;
    margin-right: 0.5rem;
}

.entitiesComponent__pillElement_label {
    width: 100%;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}