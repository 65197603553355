.modal_w_mc{
    width: fit-content;
}

.repertoireModal__body{
    min-width: 40rem;
    width: 835px;
}
input::placeholder{
    color: #B8B8B8 !important;
    font-style: italic;
}

.repertoireModal__entitiesHeader_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
    border-bottom: 2px dashed rgba(200, 200, 200, 0.8);
    padding-bottom: 0.5rem;
}

.repertoireModal__entityType{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex: 1;
    color: #505050;
}

.repertoireModal__entityType label{
    display: flex;
    white-space: nowrap;
    margin: 0;
    color: #505050;
}

.repertoireModal__entityType select {
    display: flex;
    margin: 0 0.5rem;
    color: #505050;
} 

.repertoireModal__entities_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #505050;
}
.repertoireModal__selection_container div label {
    color: #505050;
}

.repertoireModal__entities_header{
    width: 100%;
    margin-bottom: 0.5rem;
    color: #505050;
}

.repertoireModal__entities_list{
    margin-top: 0.5rem;
    max-height: 600px;
    overflow-y: auto;
    background-color: #ffffff;
    color: #505050;
}

.repertoireModal__entities_table{
    width: 800px;
    max-width: 800px;
    color: #505050;
}

.repertoireModal__entities_table td {
    width: 100%;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #505050;
}
.repertoireTitle{
    color: #2174B9
}

.repertoireModal__entities_table th {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #505050;
}