.dropzone-content{
    border: 1px solid #809FB8;
    border-radius: 18px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: middle;
    margin-bottom: 10px;
    padding: 0px 0px;
    cursor: pointer;

    color: #B8B8B8
}
.dropzone-contentInvoice{
    border: 1px solid #809FB8;
    border-radius: 18px;
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: middle;
    margin-bottom: 10px;
    padding: 6px 0px;
    cursor: pointer;
    margin: 4px;
    color: #B8B8B8
}
.selectForm{
    border: none;
    outline: none;
    color: #505050 !important;
}

.documentsListContainer{
    margin: 7px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
}

.documentsListContainer div{
    display: flex;
    width: 40%;
}
.documentsListContainer select{
    width: 40%;
}
/* .documentsListContainer svg{
    width: 20%;
} */

.filesList{
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #809fb866;
    color: #505050;
    font-size: 0.7rem;
    margin-left: 60px;
}

.dropzoneContent{
    width: 100%;
}