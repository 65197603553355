.editCreatorModal__header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
}

.editCreatorModal__header__container h5 {
    margin: 0;
}

.editCreatorModal_possibleCreators_container {
    margin: 0.5rem;
}

.editCreatorModal_possibleCreators_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.editCreatorModal_possibleCreators_item .possibleCreators_label_container {
    display: flex;
    align-items: center;
    border-bottom: 2px solid gold;
    border-radius: 3px;
    max-width: 35%;
}

.editCreatorModal_possibleCreators_item .possibleCreators_label_suspendedDocs {
    background-color: rgb(21, 137, 238);
    /* padding: 5px; */
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.25rem;
    cursor: pointer;
}

.editCreatorModal_possibleCreators_item .possibleCreators_list_container {
    width: 100%;
}