.signin__container{
    /* min-height: 692px; */
    background-image: url("../../assets/images/login_background.jpg");
    background-size: cover;
    height: 100vh;
    font-size: 0.9rem;
}

.signin__FromWrap{
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signin__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6rem 0;
}

.signin__Icon{
    /* margin-left: 2rem; */
    /* margin-top: 2rem; */
    max-width: 163px;
    max-height: 59px;
}

.signin__Icon img {
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.signin__fromContent{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 1rem; */
}
.signin__form{
    position: relative;
    background: #ffffff;
    color: #ffffff;
    /* max-width: 700px; */
    /* height: 90%; */
    width: 50%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
    /* padding: 80px 0; */
    /* border: 1px solid #797979; */
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000029;
}

.signin_language_container{
    position: absolute;
    top: 10px;
    right: 10px;
}

.signin__formH1{
    margin-bottom: 40px;
    /* color: #111111; */
    /* font-size: 20px; */
    font-weight: 400;
    text-align: center;
}

.signin__error{
    text-align: center;
    color: red;
    font-weight: bold;
    margin-bottom: 20px;

}

.signin__formGroup{
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.signin__formLabel{
    margin-bottom: 8px;
    /* font-size: 1rem; */
    /* color: #111111; */
    font-weight: 500;
}
.signin__formInput{
    padding: 0.5rem;
    /* margin-bottom: 32px; */
    border: none;
    border-bottom: 1px solid #809FB8;
    color: #809FB8;
    background-color: #ffffff;
    /* border-bottom:2px solid rgb(25, 98, 158) ; */
    /* border-radius: 9px; */
    outline: none;
}
.signin__formInput::placeholder{
    color: #809FB8;
}
.signin__formInput:focus{
    outline: none;
    border-bottom: 1px solid #2174B9;
    color: #2174B9;
}
.signin__formInput:focus::placeholder{
    color: #2174B9;
}

.signin__formBtnGroup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0 3rem 0;
}
.signin__formButton{
    background: #2174B9;
    border: 2px solid #2174B9;
    padding: 1.25rem 2.5rem;
    border-radius: 16px;
    color: #FFFFFF;
    /* font-size: 0.9rem; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: max-content;
}

.signin__formButton:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: #2174B9;
    border: 2px solid #2174B9;
}

.signin__formButton:disabled {
    transition: all 0.2s ease-in-out;
    background: #afafaf;
    border: 2px solid #FFF;
    color: #FFF;
    cursor: default;
}

.forgotPWD__link{
    margin: 16px auto;
    color: #809FB8;
    /* font-size: 0.9rem; */
    width: max-content;
}
.forgotPWD__link:hover{
    color:#2174B9 !important;
}

.newAccount__link{
    color: #809FB8;
}
.newAccount__link:hover{
    color: #2174B9;
}

.signin__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.signin__footer p {
    color: #2174B9 !important;
    font-weight: 600;
}
  
  @media (min-width: 1024px) and (max-width: 1440px) {
    
    .signin__header {
        margin: 3rem 0;
    }

    .signin__formGroup{
        margin-bottom: 1rem;
    }

    .signin__formBtnGroup{
        margin: 1rem 0;
    }
    
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    .signin__header {
        margin: 3rem 0;
    }

    .signin__formGroup{
        margin-bottom: 1rem;
    }

    .signin__formBtnGroup{
        margin: 1rem 0;
    }
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    .signin__form {
        width: 80%;
    }

    .signin__formGroup {
        width: 100%;
    }
    
    .signin__formButton {
        padding: 0.4rem 2rem;
    }

  }