.btn-close ::before{
    content: 'X';
    visibility: visible;
    color: "black";
    font-weight: bold;
}

/* pour changer la couleur de l icone consulter ce site
https://icons8.com/icons/set/close    */
.btn-close{
    background: url("../../../../assets/images/close.png") center/1em auto no-repeat;
    opacity: .9;
}


.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root	.MuiOutlinedInput-notchedOutline{

    color: red !important;
    border-color: #D9E1E7!important;

}

.notranslate{
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: inline-block; 
}
.css-1z7n62 {
    float: unset;
    display: none;
    width: none;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: hidden;
    max-width: 100%;
    -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    white-space: nowrap;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  
    width: 99%;
}

/* MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root" */