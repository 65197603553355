.box {

    position: absolute;
    cursor: move;
    color: #505050;
   width: 515px;
   border: 1px solid #809FB8;
    border-radius: 18px;
    height: 500px;
  
    user-select: none;
    background-color: white;
    z-index: 500;
    font-size: 0.9rem;
    cursor: pointer;
   
  }
  .react-draggable {
   
        top: 19vh;
        left: 60%;
  }
  .entete {
    width: 100%;
    height: 50px;   
    border-bottom: 1px solid #D9E1E7;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    cursor: pointer;
  }
  .enteteTitle{
    width: 90%;
    display: flex;
    color: #2174B9 ;
    font-size: 1.5rem;
    justify-content: center;

  
  }
    .enteteClose{
        width: 10%;
        display: flex;
        color: #2174B9 ;
        font-size: 1.7rem;
        margin: 3px;
        justify-content: flex-end;
       
    }