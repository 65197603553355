.entitiesDropDown__container{
    width: 100%;
    margin: 0.5rem 0;
}

.entitiesDropDown__label{
    font-size: 0.9rem;
    color: #809FB8;
    padding-left: 0.5rem;
}

.entitiesDropDown__addBtn{
    cursor: pointer;
    border-radius: 18px;
    border: 1px solid #809FB8;
    /* color: #505050; */
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 7px 9px 7px 13px;
    width: -moz-fit-content;
    width: fit-content;
}

.entitiesDropDown__addBtn__label{
    color: #809FB8;
}
.entitiesDropDown__addBtn__icon{
    margin-right: 5px;
    margin-left: 9px;
    color: #2174B9;
}
.entitiesDropDown__wrap{
    width: 100%;
    position: relative;
    z-index: 100;
}
.entitiesDropDown__search{
    background-color: #FFFFFF ;
    border-radius: 18px;
    Border : 1px solid #809fb866;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.25rem 0.5rem;
}
.entitiesDropDown__searchInput{
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border: none;
    border-bottom: 1px solid #FFFFFF;
    color: #505050;
    background-color: #ffffff;
    outline: none;
    display: flex;
    flex: 1;
}
.entitiesDropDown__searchInput::-ms-input-placeholder{
    color: #809FB8 !important;
    font-style: italic;
}
.entitiesDropDown__searchInput::placeholder{
    color: #809FB8 !important;
    font-style: italic;
}
/* .entitiesDropDown__searchInput:focus{
    outline: none;
    border-bottom: 1px solid #2174B9;
    color: #505050;
} */
/* .entitiesDropDown__searchInput::placeholder{
    color: #B8B8B8;
    font-style: italic;
} */
.entitiesDropDown__search__closeIcon{
    cursor: pointer;
    margin-right: 1rem;
    color: #2174B9;
}
.entitiesDropDown__search__closeIcon:hover{
    color: #2174B9;
}

.entitiesDropDown__content{
    width: 100%;
    font-size: 0.9rem;
    border: 1px solid;
    border-radius: 18px;
    color: #809fb866
}

.entitiesDropDown__content__header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5rem ;
    color: #809FB8;
}

.entitiesDropDown__content__items{
    height: 100px;
    overflow-y: auto;
}

.entitiesDropDown__content__item{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 600;

}
.entitiesDropDown__content__item:hover{
    background-color: rgba(128, 159, 184, 0.5);
}
/* .entitiesDropDown__content__item__code{
    color: #809FB8;
} */

/* td, tfoot, tr {
    border:none !important;
    border-top: 1px solid #809fb866 !important;
} */

td{
    color: #505050
}
th{
    color: #809FB8
}
thead tr, th {
    border:none !important;
}