.modal-dialog {
    width: 80%;
  height: 80%;
  min-height: 80%;
  max-width: 80%;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80%;
  }
}

.modalb.modal.show .modal-dialog {
  transform: none;
  min-height: 80%;
  width: 80% !important;
  max-width: 80%;
  margin-top:calc(100vh - 750px); 
  
  /* margin-top: 300px; */
}

.modala.modal.show .modal-dialog {
  transform: none;
  min-height: 80%;
  width: 80% !important;
  max-width: 80%;
  
  /* margin-top: 300px; */
}