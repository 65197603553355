/* Gris-bleu
#D9E1E7 / rgb(217, 225, 231) 
Rouge
#EE5A5A / rgb(238, 90, 90)
Gris-Fond
#F1F4F9 / rgb(241, 244, 249);
Violet
#C8B0E6 / rgb(194, 150, 218)
Vert
#00B8B8 / rgb(0, 184, 148)
Gris
#B8B8B8 / rgb(184, 184, 184)
Vert-Fond
#55EFC4 / rgba(85, 239, 196, 0,4)
Bleu Secondaire
#809FB8 / rgb(128, 159, 184)
*/

.ICDBlue{
    color: #2174B9 !important;
}

.lightBalck{
    color:#505050 !important;
}

.modal-content {
    border-radius: 18px !important;
}

.ADMCreationModal__body{
    color:#505050 !important;
}

.form-check-input:checked {
    background-color: #2174B9;
    border-color: #2174B9;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: .75rem;
    border-top: none;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.homeEventsIcons{
    margin: 0px 5px 0px 0px; 
}