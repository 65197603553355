
/* .download{
    text-decoration: none;
    color: #2174B9;
}
.download:hover{
    color: #809FB8;
} */

a{
    text-decoration: none;
    color: #2174B9;
}
a:hover{
    color: #809FB8;
}
