.dropDownInfo_container{
    width: fit-content;
}

.dropDownInfo_content {
    width: fit-content;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.dropDownInfo_element{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-size: 0.8rem;
}

.dropDownInfo_label{
    color: rgba(119, 119, 119, 1);
    margin-right: 0.5rem;
}

.dropDownInfo_value{
    font-weight: 700;
}